#sylius-addresses {
    button,a {
        font-size: 14px;
    }

    .btn-remove-address {
        button{
            font-size: 21px !important;
        }
        position: absolute;
        top: 0;
        right: 0;
    }
}

#account-orders{
    a.btn{
        font-size: 14px;
        padding: 6px 8px;
    }
}

#order-invoices{
    table{
        th:last-child{
            text-align: end;
        }
        td:last-child{
            text-align: end;
            a{
                &:hover{
                   margin-left: 0 !important;
                }
            }
        }
    }

}
