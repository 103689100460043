* {font-family: "Quicksand"; transition: all 0.5s;}
//@media (min-width: 1200px) {.container { max-width: 1300px;}}
h1 {font-size: 45px; font-family: "Oswald";color:$primary; text-transform: uppercase;font-weight: 600;}

a.button { transition: all 0.8s;color:#fff; font-size: 21px; background:$primary; width:250px;padding:10px 25px;border-radius: 0;}
.btn{ transition: all 0.8s;color: #fff; font-size: 16px; background: #007340; padding: 8px 10px; border-radius: 20px; font-weight: 600;}
.btn-achats {width:100%; background:$primary;color:#fff; font-size: 21px;padding:10px 25px;display: flex;}
a.button:hover {margin-left: 15px;}
a.button-line {border: 1px solid #fff;color:#fff;display: block; width:fit-content;display:table;padding: 15px;text-transform: uppercase;font-weight: 600;font-size: 20px;}
a.button-line:hover {background:$primary; color:#fff; border-color:$primary}
.btn:focus {box-shadow: none!important}
button:focus {outline: 0 !important;}
a{text-decoration: none}
a:hover {text-decoration: none;}
a.btn {background: $primary;border-radius: 0;padding: 10px 15px;color: #fff;}
a.btn.maj {display: flex; color: #fff;justify-content: center;text-transform: uppercase;font-size: 16px;}
.titre1 {font-size: 42px; font-family: "Oswald"; text-transform: uppercase;font-weight: 600;}
.subtitle {font-size: 25px; letter-spacing:1px;display: block;}
.wh {color:#fff;}
::selection{background:$primary !important;color:#fff !important}
::-moz-selection{background:$primary !important;color:#fff !important}
.form-control:focus {border-color: #c3c3c3;box-shadow: none}
.input, textarea {border-radius: 0; background: #fff;}


@media (max-width: 990px) {
    p {font-size: 14px}
    h2 {font-size:22px}
    .titre1 {font-size: 30px;}
}
