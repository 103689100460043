@import "~swiper/swiper-bundle.css";

#homepage {
    .swiper-slide {
        a{
            width: 100%;
        }

    }
    #main-slider {
        position: relative;

        .item {
            height: 660px;
            position: relative;
            @media (max-width: 990px) {
                height: 375px;
            }

            img {
                position: relative;
                object-fit: cover;
                height: auto;
                width: 100%;
                object-position: center;
            }
        }

        .caption {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 800;
            background: rgba(255, 255, 255, 0.5);
            top: 20%;
            padding: 30px;

            @media (max-width: 990px) {
                top: 10%;padding: 15px;
            }

            .subtitle {
                font-size: 20px;
                line-height: 1.3;
                max-width: 500px;
                margin-top: 10px;
                letter-spacing: 1px;
                @media (max-width: 990px) {
                    font-size: 18px;
                }
            }

            .button {
                position: absolute;
                bottom: -40px;
                @media (max-width: 990px) {
                    bottom:-46px
                }
            }
        }

        .swiper-button-next, .swiper-button-prev {
            color: white !important;
        }

        .swiper-pagination-bullet {
            width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 15px));
            height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 15px));
            display: inline-block;
            border-radius: 50%;
            background: #ffffff !important;
            opacity: 1 !important;
            //opacity: var(--swiper-pagination-bullet-inactive-opacity, 0);
        }

        .swiper-pagination-bullet-active {
            background: $primary !important;
        }
    }

    #discovery {
        margin-top: 5rem;

        .item {
            color: #333;
            background: #fff;
            display: flex;
            flex-direction: column;
            padding: 25px;
            justify-content: center;
            -webkit-box-shadow: 3px 9px 30px -2px rgba(0, 0, 0, 0.18);
            -moz-box-shadow: 3px 9px 30px -2px rgba(0, 0, 0, 0.18);
            box-shadow: 3px 9px 30px -2px rgba(0, 0, 0, 0.18);

            span {
                &.text {
                    margin-top: 15px;
                    display: block;
                    font-size: 20px;
                    text-transform: uppercase;
                    text-align: center;
                    line-height: 1.2;
                }

                &.btn {
                    width: 80%;
                    margin: 15px auto 0;
                    background: $secondary;
                    color: #fff;
                    border-radius: 100px;
                    font-weight: 700;
                }
            }

            img {
                object-fit: cover;
                object-position: bottom;
                height: 300px;

                &:hover {
                    object-position: 90% 90%;
                }
            }
        }
    }

    #products {
        margin-top: 5rem;

        h1 {
            margin-bottom: 50px;
        }
    }
}
