#terms {

    padding: 70px 30px;

    .item {
        display: flex;
        flex-direction: column;

        .image {
            i.icon-truck, i.icon-delivery {
                font-size: 80px;
            }

            i.icon-credit-card {
                font-size: 70px;
            }

            i.icon-euro {
                font-size: 60px;
            }


            margin: 0 auto 15px;
            height: 150px;
            width: 150px;
            background: url(../../media/images/circle.png) no-repeat;
            background-size: contain;
            display: flex;
            align-items: center;
            justify-content: center;

        }


        span {
            font-size: 23px;
            text-align: center;
            line-height: 1.2;
        }
    }

}

footer {
    font-size: 16px;
    background: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 100;

    @media (max-width: 990px) {
        margin: 30px 0
    }

    a {
        color: #fff;
        @media (max-width: 990px) {
            line-height: 1.5;
        }
    }

    .infos {
        display: flex;
        flex-direction: column;

        span {
            display: block;
        }
    }

    .footer-navigation {
        display: flex;
        flex-direction: column;
        @media (max-width: 990px) {
            margin: 30px 0
        }

        a {
            transition: none;
            font-size: 16px;
            line-height: 2.8;

            @media (max-width: 990px) {
                line-height: 1.5;
            }

            &:hover {
                font-weight: 600;
                color: #000
            }

        }
    }

    .sub-infos {
        margin-top: 35px;

        a {
            font-weight: 600;
            color: #fff;
        }

    }
}
