@import "./_variables.scss";
// Style du thème parent
@import "/themes/BootstrapTheme/assets/style/shop.scss";
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500;600;700&display=swap');
@import "./products/products.scss";

*{
    font-family: 'Roboto', sans-serif;
}

//body {
//    background-image: url("../media/images/25098.jpg");
//}

.goverlay {
    background: rgb(255 255 255 / 92%);
}

.top-header {
    background-color: $primary;
    padding: 12px 0;
    width: 100%;
    .container_top_header {
        max-width:1500px;
        display: flex;
        justify-content: space-between;
        margin: auto;

        a, p {
            color:#fff;
            font-size:14px;
            font-weight: 600;
            border-radius:12px;
            margin:0;
        }
        .left {
            a {
                &:not(:first-child):before {
                    content: '';
                    padding-right: 6px;
                }
                &:hover {
                    text-decoration:underline;

                }
            }
            min-width:580px;
            a {
            &:not(:first-child):before {
                content: '|';
                padding-right: 8px;
            }
            }
        }
        .right {
            min-width: 500px;
            .slug-top-header{
                display: flex;
                min-width: 380px;
                justify-content: space-evenly;
                a {
                    &:not(:first-child):before {
                        content: '|';
                        padding-right: 6px;
                    }
                    &:hover {
                        text-decoration:underline;

                    }
                }
            }
            .social {
                display: flex;
                img{
                webkit-box-shadow: 0px 10px 13px -7px #00000047, 5px 5px 15px 5px rgba(0,0,0,0);
                box-shadow: 6px 3px 12px 0px rgb(0 0 0 / 26%);
                    &:hover {
                        filter: brightness(1.2);
                    }
            }
            }
        }
        .left, .right {
            display :flex;
            align-items:center;
            justify-content:space-evenly;
        }
    }
}
nav {
    .navbar-nav {
        .nav-item {
            a {
                font-size: 16px;
                font-weight: 600;
                color: $secondary;
            }
        }
    }
}
.dropdown-menu li {
    position: relative;
}

.dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
}


.dropdown-menu.show, .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    border: none;
    border-radius: 12px;
    background: #f7f7f7d9;
    z-index: 9999;

}
#mobile-menu {
    .dropdown-menu.show{
    background: #307154}
}

#sylius_add_to_cart_customer_options{
    label {
        margin: 0.5rem 0;
        font-weight: 600;
        color: #03663a;
    }

        }

.dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    border: none;
    border-radius: 12px;
    background: #f7f7f7d9;
    z-index: 9999;

}
.dropdown-menu > li:hover i {
    transition: transform 0.3s ease;
    transform: translateX(5px);
}
////Permet de rendre les dropdown de la nav, dépliable avec un hover
//.dropdown:hover>.dropdown-menu {
//    display: block;
//}
//
//.dropdown>.dropdown-toggle:active {
//    /*Without this, clicking will make it sticky*/
//    pointer-events: none;
//}
////fin

 h1 {
     font-family: 'Caveat', cursive;
     font-size:40px;
     text-transform: none;
     font-weight: 900;
 }
 h2{
     font-family: 'Roboto', sans-serif;
     font-size:35px;
     text-transform: uppercase;
     font-weight: 600;
     color:$primary;
 }
h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.image-container {
    display:flex;
    justify-content:center;
    img {
        width: 100%;
    }
}
.banner-service {
    background-image: url("../media/images/banner-service3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1.5rem;
}
footer {

    background: $tertiary;
    color : #c3c3c3;
    span {
        b {
           color:#fff;
        }
    }

}

.table-responsive tbody img {
    max-width: 150px;
}

.btn-back {
    background-color: #f8f9fa!important;
    color: $primary!important;
    border: 1px solid $primary!important;
}
#mobile-menu {
    display:none;
}

@media screen and (max-width: 992px) {
    h1 {

        font-size:20px;

    }
    .side-bar-list {
    display: none;



}
}
@media screen and (max-width: 1100px) {
    #desktop-menu, .top-header {
        display: none;
    }
    #mobile-menu {
        display:block;
    }
}

.address-book-select {
    .btn-outline-primary {
        font-size: 12px;
    }

}

.alert-dismissible {

    margin: 0 4rem!important;
}
